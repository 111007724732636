<script lang="ts" setup>
import { computed, ref, watch, ComputedRef, Ref, toRaw } from "vue";
import {
  IonPage,
  IonButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonContent,
  IonFooter,
  IonProgressBar,
  modalController,
} from "@ionic/vue";
import { useRoute, useRouter } from "vue-router";
import {
  Routine,
  Instance,
  Execution,
  Template,
  ExecutionAnswer,
  TemplateStep,
  RoutineExecutionView,
  StepType,
  FabriqFile,
} from "@/types";
import { useRoutinesStore } from "@/store/routines";
import { useInstancesStore } from "@/store/instances";
import { useExecutionsStore } from "@/store/executions";
import { useTemplatesStore } from "@/store/templates";
import { useAnswersStore } from "@/store/answers";
import { useFilesStore } from "@/store/files";
import { useCommentsStore } from "@/store/comments";
import { useUsers } from "@/composables/useUsers";
import { useI18n } from "@/composables/useI18n";
import { useInstancePage } from "@/composables/useInstancePage";
import InstanceExecution from "@/components/business/routines/InstanceExecution.vue";
import InstanceExecutionList from "@/components/business/routines/InstanceExecutionList.vue";
import RoutineInstanceSummaryModal from "@/components/modals/RoutineInstanceSummaryModal.vue";
import RoutineInstanceCommentsModal from "@/components/modals/RoutineInstanceCommentsModal.vue";
import BackButton from "@/components/ui/BackButton.vue";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { useAddFileItem } from "@/composables/useAddFileItem";
import { formatISO } from "date-fns";
import { getNextFromLogic, getAnswerValue } from "@/utils/routines";
import { useTicketsStore } from "@/store/tickets";
import { useCanStartRoutine } from "@/composables/useCanStartRoutine";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";

const tunnel = (action: string) => {
  tracker.tunnel(
    "routine",
    "routine page",
    RecordCategory.Routines,
    ActionType.Process,
    action
  );
};

const route = useRoute();
const { users } = useUsers();
const router = useRouter();
const forceView: Ref<null | boolean> = ref(null);
const content = ref();
const { t } = useI18n();
const commentsStore = useCommentsStore();
const routinesStore = useRoutinesStore();
const instancesStore = useInstancesStore();
const executionsStore = useExecutionsStore();
const answersStore = useAnswersStore();
const templatesStore = useTemplatesStore();
const filesStore = useFilesStore();
const ticketsStore = useTicketsStore();

const loaded = computed(() => {
  return routine.value && instance.value && template.value && execution.value;
});

const step = ref(1);
const nbSteps = computed(() => {
  if (!template.value?.steps || !template.value.steps.length) return 1;
  return template.value.steps.filter(
    (s: TemplateStep) => !s.parent // !== StepType.Checklist
  ).length;
});
const isList = computed(() => {
  if (forceView.value !== null) return forceView.value;
  if (!routine.value) return true;
  return (
    routine.value.config?.defaultExecutionView &&
    routine.value.config?.defaultExecutionView === RoutineExecutionView.List
  );
});

const instance: ComputedRef<Instance> = computed(() => {
  if (!route.params?.instanceId) return null;
  return instancesStore.collection.find(
    (t: Instance) => t.uuid === route.params.instanceId
  );
});

const isComplete = (a: any) => {
  if (a.step?.optional) return true;
  if (a.step.step_type === StepType.Divider) return true;
  if ((a.choices && a.choices.length) || (a.value && a.value.length))
    return true;
  if (a.step.step_type === StepType.Attachment) {
    const files = filesStore.collection.filter((f: any) => {
      return f.answer === a.id;
    });
    if (files.length) return true;
  }
  return false;
};

const jumpedSteps: Ref<Array<number>> = ref([]);

const steps = computed(() => {
  if (!template.value || !template.value.steps) return [];
  const steps = template.value.steps.filter((s: TemplateStep) => !s.parent);
  steps.sort((a: TemplateStep, b: TemplateStep) => a.order - b.order);
  return steps.map((s: TemplateStep, index: number) => {
    const answer = answers.value.find((a: any) => a.step.id === s.id);
    return { ...s, answer, index };
  });
});

const uncompletedSteps = computed(() => {
  const uncompleted: Array<any> = [];
  const sorted = answers.value.filter((a: ExecutionAnswer) => !a.step?.parent);
  sorted.sort((a: ExecutionAnswer, b: ExecutionAnswer) => {
    return a.step.order - b.step.order;
  });
  sorted.forEach((a: any, index: number) => {
    if (isComplete(a)) return;
    if (a.step.parent) return;
    if (a.step.step_type === StepType.Checklist) {
      const ans = answers.value.filter((aa: any) => {
        if (aa.step.parent !== a.step.id) return false;
        if (isComplete(aa)) return false;
        return true;
      });
      if (ans.length > 0) {
        uncompleted.push({ ...a, index: +index + 1 });
      }
      return;
    }
    if (a.step.step_type === StepType.Picture) {
      if (a.files && a.files.length) return;
    }
    uncompleted.push({ ...a, index: +index + 1 });
  });
  return uncompleted;
});

const missingSteps = computed(() => {
  return uncompletedSteps.value.filter((a: any) => {
    if (jumpedSteps.value.includes(a.index)) return false;
    if (optionalSteps.value.includes(a.step.id)) return false;
    return true;
  });
});

const routine: ComputedRef<Routine> = computed(() => {
  if (!instance.value) return null;
  return routinesStore.collection.find(
    (t: Routine) => t.id === instance.value.routine_id
  );
});
const execution: ComputedRef<Execution> = computed(() => {
  if (!instance.value) return null;
  const id = instance.value.id || instance.value.uuid;
  return executionsStore.collection.find((t: Execution) => t.instance === id);
});
const template: ComputedRef<Template> = computed(() => {
  if (!instance.value) return null;
  const id = instance.value.template_id || routine.value.template_id;
  return templatesStore.collection.find((t: Template) => t.id === id);
});
const answers: ComputedRef<Array<ExecutionAnswer>> = computed(() => {
  if (!execution.value) return [];
  const id = execution.value.id || execution.value.uuid;
  return answersStore.collection.filter((t: ExecutionAnswer) => {
    return t.instance_execution === id;
  });
});

watch(
  step,
  (v) => {
    if (!execution.value) return;
    executionsStore.update(execution.value.uuid, {
      current_step_index: v,
    });
  },
  { immediate: true }
);

const previous = async () => {
  tracker.begin(
    "previousStep",
    "Routine previous click",
    RecordCategory.Routines,
    ActionType.Process
  );
  do {
    step.value--;
  } while (jumpedSteps.value.includes(step.value));
  if (step.value < 1) step.value = 1;
};

const next = async () => {
  tracker.begin(
    "nextStep",
    "Routine next click",
    RecordCategory.Routines,
    ActionType.Process
  );

  const currentStep = steps.value[step.value - 1];
  const currentAnswer = answers.value.find(
    (a: ExecutionAnswer) => a.step.id === currentStep.id
  );
  const nextFromLogic = getNextFromLogic(
    currentStep,
    getAnswerValue(currentAnswer),
    template.value
  );

  if (nextFromLogic === undefined) {
    if (step.value < nbSteps.value + 1) {
      return (step.value = step.value + 1);
    } else {
      return step.value;
    }
  }

  if (nextFromLogic === "confirmationScreen") {
    return (step.value = nbSteps.value + 1);
  }

  step.value = steps.value.findIndex((s) => s.id === +nextFromLogic) + 1;
};

const save = async (ev: CustomEvent) => {
  ev.preventDefault();
  ev.stopPropagation();
  if (!execution.value?.uuid || !instance.value?.uuid) return router.back();
  const newInstance = {
    ...fabriqDeepClone(toRaw(instance.value)),
    has_started: true,
  };
  instancesStore.merge([newInstance]);
  executionsStore.save();
  ticketsStore.save();
  tunnel("save and exit");
  commentsStore.clearDraft();
  router.back();
};

const showSummary = async () => {
  const modal = await modalController.create({
    component: RoutineInstanceSummaryModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    componentProps: {
      uncompletedSteps: uncompletedSteps.value,
      steps: template.value.steps,
      answers: answers.value,
      current: step.value - 1,
      onDone: (index: number) => {
        step.value = index + 1;
        modal.dismiss();
      },
      onCancel: () => modal.dismiss(),
    },
  });
  tunnel("summary");
  return modal.present();
};

const addFiles = async (answer: ExecutionAnswer) => {
  const addFile = async (file: FabriqFile) => {
    const now = formatISO(new Date());
    await filesStore.add({
      ...file,
      answer: answer.id || answer.uuid,
      created_at: now,
      updated_at: now,
    });
    filesStore.save();
  };
  const { addFileItem } = useAddFileItem(
    tunnel,
    addFile,
    "add | file | routine"
  );
  await addFileItem();
};

const showComments = async (answer?: ExecutionAnswer) => {
  const modal = await modalController.create({
    component: RoutineInstanceCommentsModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 1],
    initialBreakpoint: 1,
    componentProps: {
      users: users.value,
      instance: instance.value,
      answers: answers.value,
      answer,
      current: step.value - 1,
      onDone: (index: number) => {
        step.value = index + 1;
        modal.dismiss();
      },
      onCancel: () => modal.dismiss(),
    },
  });
  tunnel("comments");
  return modal.present();
};

const nbComments = computed(() => {
  if (!instance.value?.id) return 0;
  return commentsStore.collection.filter(
    (c: any) => c.instance === instance.value?.id
  ).length;
});

const nextFromLogic = (next: number) => {
  jumpedSteps.value = jumpedSteps.value.filter((idx) => idx < step.value);
  if (next) {
    for (let i = step.value + 1; i < next; i++) {
      if (!jumpedSteps.value.includes(i)) jumpedSteps.value.push(i);
    }
  }
};

const { optionalSteps } = useInstancePage(template);

const { canStartRoutine, canBeResent } = useCanStartRoutine(
  instance,
  ref(false)
);
</script>

<template>
  <ion-page class="main-page">
    <ion-header mode="ios" v-if="loaded">
      <ion-toolbar class="ion-padding-start transparent-toolbar">
        <back-button
          slot="start"
          @back="save"
          :text="t('common.saveAndExit')"
        />
        <ion-buttons slot="end">
          <ion-button
            @click="showComments"
            class="toolbar-end-button"
            v-if="canStartRoutine"
          >
            <font-icon
              name="mode_comment"
              outlined
              material
              size="1.25"
              color="var(--ion-color-primary-shade)"
            />
            <small
              v-if="nbComments"
              style="color: var(--ion-color-primary-shade)"
              >&nbsp;{{ nbComments }}</small
            >
          </ion-button>
          <ion-button
            class="toolbar-end-button"
            @click="forceView = forceView === null ? !isList : !forceView"
          >
            <font-icon
              name="view_day"
              material
              size="1.25"
              color="var(--ion-color-primary-shade)"
            />
          </ion-button>
          <ion-button @click="showSummary" class="toolbar-end-button">
            <font-icon
              name="menu"
              material
              size="1.25"
              color="var(--ion-color-primary-shade)"
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content
      :fullscreen="true"
      class="ion-padding-bottom"
      ref="content"
      v-if="loaded"
    >
      <div class="routine-header">
        <div class="routine-infos">
          <div class="routine-title">{{ routine.title }}</div>
          <div class="routine-steps" v-if="step <= nbSteps">
            {{ step }}/{{ nbSteps }}
          </div>
          <div
            class="routine-steps"
            v-else-if="step > nbSteps && missingSteps.length"
          >
            ⚠️&nbsp;&nbsp;&nbsp;{{ t("routines.incomplete") }}
          </div>
          <div class="routine-steps" v-else>
            🎉&nbsp;&nbsp;&nbsp;{{ t("routines.complete") }}
          </div>
        </div>
        <ion-progress-bar :value="step / nbSteps" />
      </div>
      <instance-execution
        v-if="!isList"
        :template="template"
        :steps="steps"
        :routine="routine"
        :instance="instance"
        :execution="execution"
        :answers="answers"
        :uncompletedSteps="uncompletedSteps"
        :missingSteps="missingSteps"
        :jumpedSteps="jumpedSteps"
        :optionalSteps="optionalSteps"
        :currentStep="step - 1"
        :readonly="!canStartRoutine"
        :canBeResent="canBeResent"
        @comment="showComments($event)"
        @file="addFiles($event)"
        @current="step = $event"
        @next="nextFromLogic"
      />
      <instance-execution-list
        v-else
        :template="template"
        :steps="steps"
        :routine="routine"
        :instance="instance"
        :execution="execution"
        :answers="answers"
        :uncompletedSteps="uncompletedSteps"
        :missingSteps="missingSteps"
        :jumpedSteps="jumpedSteps"
        :optionalSteps="optionalSteps"
        :currentStep="step - 1"
        :readonly="!canStartRoutine"
        :canBeResent="canBeResent"
        @comment="showComments($event)"
        @file="addFiles($event)"
        @current="step = $event"
        @next="nextFromLogic"
      />
    </ion-content>
    <ion-footer class="ion-no-border" v-if="loaded && !isList">
      <ion-toolbar
        class="transparent-toolbar steps-navigation ion-padding-vertical"
      >
        <ion-buttons slot="start">
          <ion-button @click="previous" :disabled="step === 1">
            <font-icon
              name="arrow_back"
              material
              size="1.5"
              color="var(--ion-color-primary-shade)"
            />
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button @click="next" :disabled="step === nbSteps + 1">
            <font-icon
              name="arrow_forward"
              material
              size="1.5"
              :color="
                uncompletedSteps.findIndex((s) => s.index === step) < 0
                  ? 'var(--ion-color-primary)'
                  : 'var(--ion-color-primary-shade)'
              "
            />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<style scoped>
.save-button {
  --color: var(--ion-color-primary-shade);
  font-size: var(--font-size-m);
}

.save-button .ion-margin-end {
  margin-right: calc(var(--ion-padding) / 2);
}

.steps-navigation {
  --background: var(--ion-color-primary-contrast) !important;
  padding: var(--ion-padding) calc(var(--ion-padding) * 4);
}

.routine-header {
  padding: var(--ion-padding);
}

.routine-infos {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.routine-title {
  margin-bottom: calc(var(--ion-padding) / 2);
}

ion-progress-bar::part(progress) {
  color: var(--ion-color-secondary);
  background-color: var(--ion-color-secondary);
}

ion-progress-bar::part(track) {
  color: var(--ion-background-color-shade);
  background-color: var(--ion-background-color-shade);
}
</style>
