<script lang="ts" setup>
import { computed, ComputedRef, onMounted, ref, Ref, watch } from "vue";
import { useDatapointsStore } from "@/store/datapoints";
import { useIndicatorsStore } from "@/store/indicators";
import { useRulesStore } from "@/store/rules";
import {
  Datapoint,
  DetailedValue,
  ExecutionAnswer,
  Indicator,
  IndicatorRule,
  IndicatorType,
  Routine,
  RuleCondition,
  Template,
  TemplateStep,
} from "@/types";
import { useConvertDateOption } from "@/composables/useConvertDateOption";
import { useI18n } from "@/composables/useI18n";
import { IonText } from "@ionic/vue";

import StepDate from "@/components/business/routines/steps/StepDate.vue";

import CounterInput from "./CounterInput.vue";
import TimeSeriesInput from "./TimeSeriesInput.vue";
import StatusInput from "./StatusInput.vue";
import { useClientStore } from "@/store/client";
import { storeToRefs } from "pinia";
import { useRoutineNudgeCreateTicket } from "@/composables/useRoutineNudgeCreateTicket";
import { buildDatapointId } from "@/utils/datapoints";

const { t } = useI18n();

const datapointsStore = useDatapointsStore();
const indicatorsStore = useIndicatorsStore();
const clientStore = useClientStore();

const rulesStore = useRulesStore();

const { convertDateOption } = useConvertDateOption();

const { useKPIv3 } = storeToRefs(clientStore);

const inputComponent = computed(() => {
  switch (indicator.value?.indicator_type) {
    case IndicatorType.Counter:
      return CounterInput;
    case IndicatorType.TimeSeries:
      return TimeSeriesInput;
    case IndicatorType.Status:
      return StatusInput;
    default:
      return null;
  }
});

const props = defineProps<{
  step: TemplateStep;
  answer: ExecutionAnswer;
  template: Template;
  routine: Routine;
  executionDate: Date;
}>();

const detailedValue = ref({
  id: null,
  value: null,
  target: null,
  target_met: null,
  manual_target_met: null,
}) as Ref<DetailedValue>;

const canChange = computed(() => {
  if (!props.step.config.prevent_override) return true;
  if (datapoint.value?.value !== null && datapoint.value?.value !== undefined)
    return false;
  return true;
});

const emit = defineEmits<{
  (event: "detailed_value", value: DetailedValue): void;
}>();

const stepConfigDateOption = ref<string>("");

onMounted(() => {
  const savedDate = props.answer.detailed_value?.end_date;
  stepConfigDateOption.value =
    savedDate ??
    convertDateOption(props.step.config.dateOption, props.executionDate);
  loadDataPoint(indicator.value, stepConfigDateOption.value);
  initializeValues();
});

const indicator: ComputedRef<Indicator> = computed(() => {
  return indicatorsStore.collection.find(
    (indicator: Indicator) => indicator.id === props.step.config.indicatorId
  );
});

const datapoint = computed(() => {
  const dp = datapointsStore.collection.find((datapoint: Datapoint) => {
    return (
      datapoint.indicator === props.step.config.indicatorId &&
      datapoint.end_date === stepConfigDateOption.value
    );
  });
  return dp;
});

const rules = computed(() => {
  if (indicator.value.v3_indicator_id) {
    return indicator.value.rule ? [indicator.value.rule] : [];
  }
  return rulesStore.collection.find((rule: IndicatorRule[]) => {
    if (rule.length) {
      return rule[0].object_id === props.step.config.indicatorId;
    }
  });
});

const isAutomatic = computed(() => {
  if (!indicator.value.config.rules_active) return false;
  if (!rules.value?.length) return false;
  const nb = rules.value.reduce(
    (acc: number, r: any) => acc + r.conditions.length || 0,
    0
  );
  return nb > 0;
});

const hasOnlyConstantRules = computed(() => {
  if (!rules.value?.length) return false;
  return rules.value.every((r: any) =>
    r.conditions.every((c: any) => c.type === "constant")
  );
});

const calculateTargetMet = () => {
  if (!isAutomatic.value) return;
  if (detailedValue.value.value === null) return;
  const value = +detailedValue.value.value;
  if (!hasOnlyConstantRules.value && detailedValue.value.target === null)
    return;
  const target = +(detailedValue.value.target ?? 0);
  if (indicator.value.indicator_type !== IndicatorType.TimeSeries) return;
  if (!indicator.value?.config?.rules_active) return;
  if (!rules.value?.length) return;
  let targetMet: boolean | null = null;
  rules.value.forEach((rule: IndicatorRule) => {
    rule.conditions.forEach((condition: RuleCondition) => {
      console.log(condition, getConditionResult(value, target, condition));
      if (condition.conjunction === "and") {
        targetMet = targetMet && getConditionResult(value, target, condition);
      } else if (condition.conjunction === "or") {
        targetMet = targetMet || getConditionResult(value, target, condition);
      } else {
        targetMet = getConditionResult(value, target, condition);
      }
    });
  });
  setTargetMet(targetMet);
};

const getConditionResult = (
  value: number,
  target: number,
  condition: RuleCondition
) => {
  const conditionValue =
    condition.type === "constant"
      ? +condition.value
      : target * +condition.coefficient;
  switch (condition.operator) {
    case "<":
      return value < conditionValue;
    case "<=":
      return value <= conditionValue;
    case "=":
      return value == conditionValue;
    case "!=":
      return value != conditionValue;
    case ">=":
      return value >= conditionValue;
    case ">":
      return value > conditionValue;
  }
};

const setValue = (value: number | null | string) => {
  if (value === props.answer.value) return;
  detailedValue.value.value = value;
  calculateTargetMet();
  detailedValue.value.indicatorId = Number(indicator.value.id);
  detailedValue.value.end_date = stepConfigDateOption.value;
  detailedValue.value.id = buildDatapointId(
    Number(indicator.value.id),
    stepConfigDateOption.value,
    indicator.value.frequency
  );
  emit("detailed_value", detailedValue.value);
};

const setTarget = (target: number | null | string) => {
  if (target === props.answer?.detailed_value?.target) return;
  detailedValue.value.target = target;
  calculateTargetMet();
  detailedValue.value.indicatorId = Number(indicator.value.id);
  detailedValue.value.end_date = stepConfigDateOption.value;
  detailedValue.value.id = buildDatapointId(
    Number(indicator.value.id),
    stepConfigDateOption.value,
    indicator.value.frequency
  );
  emit("detailed_value", detailedValue.value);
};

const setTargetMet = (targetMet: boolean | null) => {
  if (targetMet === props.answer?.detailed_value?.target_met) return;
  detailedValue.value.target_met = targetMet;
  detailedValue.value.manual_target_met = targetMet;
  detailedValue.value.indicatorId = Number(indicator.value.id);
  detailedValue.value.end_date = stepConfigDateOption.value;
  detailedValue.value.id = buildDatapointId(
    Number(indicator.value.id),
    stepConfigDateOption.value,
    indicator.value.frequency
  );
  emit("detailed_value", detailedValue.value);
};

async function setDate(newDate: string) {
  if (stepConfigDateOption.value === newDate) return;
  const indicatorId = indicator.value.id;
  const existingDatapoint = datapointsStore.collection.find(
    (datapoint: Datapoint) => {
      return (
        datapoint.indicator === props.step.config.indicatorId &&
        datapoint.end_date === newDate
      );
    }
  );
  if (!existingDatapoint && indicatorId) {
    loadDataPoint(indicator.value, newDate);
  }
  stepConfigDateOption.value = newDate;
  initializeValues();
}

const loadDataPoint = (indicator: Indicator, date: string) => {
  if (!indicator.id) {
    return;
  }
  datapointsStore.load(indicator.id, false, {
    useKpiV3: useKPIv3.value,
    selected_date: date,
    frequency: indicator.frequency,
  });
};
const initializeValues = () => {
  let id = null;
  let value = null;
  let target = null;
  let target_met = null;
  if (!indicator.value?.id) return;

  if (
    indicator.value.default_target !== null ||
    indicator.value.default_target !== undefined
  ) {
    target = indicator.value.default_target;
  }

  const hasFillAnswer = Boolean(
    props.answer.detailed_value?.end_date === stepConfigDateOption.value &&
      (props.answer.detailed_value?.value ||
        props.answer.detailed_value?.target ||
        props.answer.detailed_value?.target_met)
  );

  if (
    hasFillAnswer &&
    (!datapoint.value ||
      (datapoint.value && !props.step.config?.prevent_override))
  ) {
    value = props.answer.detailed_value?.value;
    target = props.answer.detailed_value?.target;
    target_met = props.answer.detailed_value?.target_met;
  }

  if (
    (!hasFillAnswer && datapoint.value) ||
    (datapoint.value && props.step.config?.prevent_override)
  ) {
    value = datapoint.value.value;
    target = datapoint.value.target;
    target_met = datapoint.value.target_met;
  }
  if (stepConfigDateOption.value && indicator.value.frequency) {
    id = buildDatapointId(
      Number(indicator.value.id),
      stepConfigDateOption.value,
      indicator.value.frequency
    );
  }

  calculateTargetMet();
  detailedValue.value = {
    id,
    value,
    target,
    target_met,
    manual_target_met: target_met,
    indicatorId: indicator.value.id,
    end_date: stepConfigDateOption.value,
  };
  emit("detailed_value", detailedValue.value);
};

watch(
  datapoint,
  (v, o) => {
    if (!v || v === o) return;
    initializeValues();
  },
  { immediate: true }
);

const { hasNudgeCreateTicketActive } = useRoutineNudgeCreateTicket(
  computed(() => props.step),
  computed(() => props.answer),
  computed(() => detailedValue.value.manual_target_met),
  indicator
);
</script>

<template>
  <div>
    <p class="chip" v-if="indicator">{{ indicator.name }}</p>
    <StepDate
      class="step-date"
      :step="step"
      :answer="{ value: stepConfigDateOption }"
      :frequency="indicator.frequency"
      @value="setDate"
    />
    <p v-if="!canChange" class="hint ion-padding-top">
      {{ t("routines.datapointOverridePrevention") }}
    </p>
    <component
      v-if="inputComponent && indicator"
      :class="{ 'look-disabled': !canChange }"
      :is="inputComponent"
      :key="step.id"
      :step="step"
      :answer="answer"
      :indicator="indicator"
      :datapoint="datapoint"
      :rules="rules"
      :disabled="!canChange"
      @value="setValue"
      @target="setTarget"
      @target_met="setTargetMet"
    />
    <ion-text v-if="hasNudgeCreateTicketActive" class="create-ticket-alert">
      <font-icon name="warning" material />
      <span> {{ t("routines.nudgeCreateTicketAlert") }} </span>
    </ion-text>
  </div>
</template>

<style>
.chip {
  width: fit-content;
  margin-top: 2rem;
  background-color: var(--ion-background-color-shade);
  border: 1px solid var(--ion-border-color);
  color: var(--ion-color-primary);
  border-radius: 50px;
  padding: calc(var(--ion-padding) / 2) var(--ion-padding);
  font-size: var(--font-size-s);
}

.step-date {
  margin-top: 8px;
}
</style>
