<script lang="ts" setup>
import { Clipboard } from "@capacitor/clipboard";
import { computed, nextTick, onMounted, ref, toRaw, watch } from "vue";
import FileList from "@/components/business/FileList.vue";
import {
  IonContent,
  IonHeader,
  IonPage,
  useIonRouter,
  IonToolbar,
  IonButton,
  IonButtons,
  modalController,
  alertController,
} from "@ionic/vue";
import BackButton from "@/components/ui/BackButton.vue";
import { useRoute } from "vue-router";
import { useEventsStore } from "@/store/events";
import {
  EventType,
  FabriqEvent,
  EventTypePropertyWithMandatory,
  FabriqEventPayload,
  EventProperty,
  FabriqEventWithReadableProperties,
  User,
  MediaType,
} from "@/types";
import { localize } from "@/utils/localize";
import { useEventTypesStore } from "@/store/eventTypes";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { useFabriqStore } from "@/store/fabriq";
import EventPropertyField from "@/components/business/events/EventPropertyField.vue";
import EventOptionsModal from "@/components/modals/EventOptionsModal.vue";
import config from "@/config";
import { useEventsWithPropertiesStore } from "@/store/eventsWithProperties";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import { useI18n } from "vue-i18n";
import EventMissingFields from "@/components/business/events/EventMissingFields.vue";
import EventDependencies from "@/components/business/events/EventDependencies.vue";
import { useEventFiltersStore } from "@/store/eventFilters";
import { formatDistanceToNow, parseISO } from "date-fns";
import { getDateFnsLocales } from "@/i18n";
import { useClientStore } from "@/store/client";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";
import { useEventFiles } from "@/composables/useEventsFiles";
import { useAddFileItem } from "@/composables/useAddFileItem";
import tracker from "@/utils/tracker";
import { ActionType, RecordCategory } from "@/classes/PerformanceTracker";
import { fabriqDeepClone } from "@/services/fabriqDeepClone";

const router = useIonRouter();
const fabriqStore = useFabriqStore();
const route = useRoute();
const eventsStore = useEventsStore();
const eventsWithPropertiesStore = useEventsWithPropertiesStore();
const diamondDependenciesStore = useDiamondDependenciesStore();
const clientStore = useClientStore();

const eventTypeStore = useEventTypesStore();
const eventFiltersStore = useEventFiltersStore();
const { t } = useI18n();

const { eventsWithProperties } = storeToRefs(eventsWithPropertiesStore);
const { locale } = fabriqStore;

const eventId = ref<string | undefined>(route.params?.id as string);
const creatingEvent = computed(() => !eventId.value);
const eventType = computed<EventType>(() => {
  if (route.params?.eventTypeId) {
    return eventTypeStore.collection.find(
      (e: EventType) => e.id === route.params?.eventTypeId
    );
  } else if (eventId.value) {
    const event = eventsStore.collection.find(
      (e: FabriqEvent) => e.uuid === eventId.value
    );
    if (!event) {
      return "";
    }
    return eventTypeStore.collection.find(
      (e: EventType) => e.id === event.eventTypeId
    );
  }
  return "";
});

const canEdit = computed(() => {
  if (!existingEvent.value && creatingEvent.value) {
    return true;
  }
  if (!existingEvent.value) {
    return false;
  }
  return eventFiltersStore.canUserEdit(existingEvent.value.id);
});

const existingEvent = computed<FabriqEventWithReadableProperties | undefined>(
  () => {
    return eventsWithProperties.value.find(
      (e: FabriqEvent) => e.uuid === eventId.value
    );
  }
);

const createdSince = computed(() => {
  if (!existingEvent.value) return "";

  return formatDistanceToNow(
    parseISO(new Date(existingEvent.value.createdAt).toISOString()),
    {
      locale: getDateFnsLocales(fabriqStore.locale),
    }
  );
});

const creator = computed(() => {
  if (!existingEvent.value) return "";
  const creator = clientStore.usersWithFutureUserId.find(
    (u: User) => u.future_user_id === existingEvent.value?.createdBy
  );
  if (!creator) return null;
  const fullName = creator?.profile?.full_name
    ? creator.profile.full_name
    : `${creator.first_name} ${creator.last_name}`;
  if (fullName.length > 20) {
    return `${creator.first_name.substring(0, 1).toLocaleUpperCase()}. ${
      creator.last_name
    }`;
  }
  return fullName;
});

const event = ref<FabriqEventPayload & { uuid: string }>({
  uuid: uuidv4(),
  eventTypeId: eventType.value.id,
  properties: [],
  files: [],
});

const { files } = useEventFiles(event);

const sortedEventTypeProperties = computed<EventTypePropertyWithMandatory[]>(
  () => {
    if (!eventType.value.properties) {
      return [];
    }
    const dateProperty = eventType.value.properties.find(
      (p: { type: string }) => p.type === "datetime"
    );
    const zoneProperty = eventType.value.properties.find(
      (p: { type: string }) => p.type === "zone"
    );
    const otherProperties = eventType.value.properties.filter(
      (p: { type: string }) => p.type !== "datetime" && p.type !== "zone"
    );
    return [
      { ...dateProperty, mandatory: true },
      { ...zoneProperty, mandatory: true },
      ...otherProperties,
    ] as EventTypePropertyWithMandatory[];
  }
);

const requiredEventTypeProperties = computed<EventTypePropertyWithMandatory[]>(
  () => {
    return sortedEventTypeProperties.value.filter((p) => p.required);
  }
);

const missingFields = computed(() => {
  return requiredEventTypeProperties.value.filter((p: { id: string }) => {
    return !event.value?.properties.find(
      (ep: { eventPropertyTypeId: string }) => ep.eventPropertyTypeId === p.id
    );
  });
});

const nbMissingFields = computed(() => missingFields.value.length);
const nbRequiredFields = computed(
  () => requiredEventTypeProperties.value.length
);

const shownEventTypeProperties = ref<EventTypePropertyWithMandatory[]>();

const hiddenEventTypeProperties = ref<EventTypePropertyWithMandatory[]>();

const eventChangedFromStore = computed<boolean>(() => {
  if (existingEvent.value) {
    const allPropertyTypeIds = new Set([
      ...existingEvent.value.properties.map((p) => p.eventPropertyTypeId),
      ...event.value.properties.map((p) => p.eventPropertyTypeId),
    ]);
    for (const propTypeId of allPropertyTypeIds) {
      const storeProperty = existingEvent.value.properties.find(
        (p) => p.eventPropertyTypeId === propTypeId
      );
      const currentProperty = event.value.properties.find(
        (p) => p.eventPropertyTypeId === propTypeId
      );
      if (storeProperty?.value !== currentProperty?.value) {
        return true;
      }
    }
    return filesChanged.value;
  }
  return true;
});

let nbFiles = 0;
onMounted(() => {
  if (existingEvent.value) {
    nbFiles = existingEvent.value.files.reduce((acc, f) => {
      acc = f.id ?? 0 > acc ? f.id ?? 0 : acc;
      return acc;
    }, 0);
    event.value = {
      id: existingEvent.value.id,
      uuid: existingEvent.value.uuid as string,
      eventTypeId: existingEvent.value.eventTypeId,
      properties: existingEvent.value.properties,
      files: existingEvent.value.files ?? [],
    };
  }
  nextTick(() => {
    const toShowProperties: EventTypePropertyWithMandatory[] = [];
    const toHideProperties: EventTypePropertyWithMandatory[] = [];

    sortedEventTypeProperties.value.forEach((p) => {
      if (!canEdit.value) {
        toShowProperties.push(p);
        return;
      }
      const eventPropertyValue = event.value?.properties.find(
        (ep) =>
          ep.eventPropertyTypeId === p.id &&
          ep.value !== undefined &&
          ep.value !== null &&
          ep.value !== ""
      );
      if (p.required || eventPropertyValue) {
        toShowProperties.push(p);
      } else {
        toHideProperties.push(p);
      }
    });
    shownEventTypeProperties.value = toShowProperties;
    hiddenEventTypeProperties.value = toHideProperties;
  });
});

const alertOnClose = async () => {
  if (missingFields.value.length > 0) {
    const mandatoryFieldsMissing = missingFields.value.some((p) => p.mandatory);
    const alertInput: {
      header: string;
      message: string;
      buttons: { text: string; role: string; cssClass?: string }[];
    } = {
      header: "",
      message: "",
      buttons: [],
    };
    if (mandatoryFieldsMissing) {
      const missingFieldLabels = missingFields.value
        .filter((p) => p.mandatory)
        .map((p) => `<strong>${localize(p.label, locale, 0)}</strong>`)
        .join(", ");
      alertInput.header = t("eventContent.missingMandatoryFieldsHeader");
      alertInput.message = `${t(
        "eventContent.missingMandatoryFieldsMessage"
      )} ${missingFieldLabels}`;
      alertInput.buttons = [
        {
          text: t("eventContent.close"),
          role: "close",
          cssClass: "alert-modal-nok-small",
        },
        {
          text: t("eventContent.completeEvent"),
          role: "cancel",
          cssClass: "alert-modal-ok-small",
        },
      ];
    } else {
      const missingFieldLabels = missingFields.value
        .filter((p) => p.required)
        .map((p) => `<strong>${localize(p.label, locale, 0)}</strong>`)
        .join(", ");
      alertInput.header = t("eventContent.missingRequiredFieldsHeader");
      alertInput.message = `
       ${
         creatingEvent.value
           ? t("eventContent.missingRequiredFieldsWithCreateMessage")
           : t("eventContent.missingRequiredFieldsWithSaveMessage")
       } ${missingFieldLabels}`;

      alertInput.buttons = [
        {
          text: creatingEvent.value
            ? t("eventContent.create")
            : t("eventContent.save"),
          role: "save",
          cssClass: "alert-modal-nok-small",
        },
        {
          text: t("eventContent.completeEvent"),
          role: "cancel",
          cssClass: "alert-modal-ok-small",
        },
      ];
    }

    const alert = await alertController.create({
      cssClass: "alert-modal",
      header: alertInput.header,
      message: alertInput.message,
      buttons: alertInput.buttons,
    });
    alert.present();
    const { role } = await alert.onDidDismiss();
    switch (role) {
      case "save": {
        return { shouldSave: true, shouldDismiss: true };
      }
      case "close": {
        return { shouldSave: false, shouldDismiss: true };
      }
      case "cancel":
      case "backdrop": {
        return { shouldSave: false, shouldDismiss: false };
      }
      default: {
        throw new Error("Unknown role");
      }
    }
  }
  return { shouldSave: true, shouldDismiss: true };
};

const dismissEventModal = (action?: string) => {
  router.canGoBack() ? router.back() : router.push("/main/events");
  mixpanelTracker.track("close | event | event modal", {
    action,
  });
};

const saveEvent = () => {
  const rawEvent = {
    ...fabriqDeepClone(toRaw(event.value)),
    files: event.value.files.map((f) => fabriqDeepClone(toRaw(f))),
  };
  if (creatingEvent.value) {
    eventsStore.add(rawEvent);
  } else {
    mixpanelTracker.track("save | event | event modal");
    eventsStore.update(event.value.uuid, rawEvent);
  }
  eventsStore.save();
};

const closeEvent = async () => {
  if (!eventChangedFromStore.value) {
    dismissEventModal("noChanges");
    return;
  }
  const { shouldSave, shouldDismiss } = await alertOnClose();

  if (shouldSave) {
    saveEvent();
  }
  if (shouldDismiss) {
    dismissEventModal(shouldSave ? "savedChanges" : "closedWithoutSaving");
  }
};

watch(nbMissingFields, (value, oldValue) => {
  if (!existingEvent.value) {
    return;
  }
  if (oldValue && value === 0) {
    mixpanelTracker.track("complete event | event | event mobile tab");
  }
});

const showProperty = async (id: string) => {
  const eventPropertyType = sortedEventTypeProperties.value.find(
    (p) => p.id === id
  );
  if (!eventPropertyType) {
    return;
  }
  shownEventTypeProperties.value = [
    ...(shownEventTypeProperties.value ?? []),
    eventPropertyType,
  ];
  hiddenEventTypeProperties.value = hiddenEventTypeProperties.value?.filter(
    (p) => p.id !== id
  );
};
const updateEvent = (payload: EventProperty[]) => {
  event.value = {
    ...event.value,
    properties: payload,
  };
};
const openEventOptions = async () => {
  const modal = await modalController.create({
    component: EventOptionsModal,
    canDismiss: true,
    mode: "ios",
    breakpoints: [0, 0.3, 0.5],
    initialBreakpoint: 0.3,
    componentProps: {
      ticket: event.value,
      canEdit: canEdit.value,
      onDone: (action: string) => {
        switch (action) {
          case "copy":
            if (!event.value) {
              return;
            }
            mixpanelTracker.track("copy | url event | modal");
            const webAppUrl = fabriqStore.subdomain || config.webappUrl;
            Clipboard.write({
              string: `${webAppUrl}/?event=${event.value.id}`,
            });
            break;
          case "delete":
            mixpanelTracker.track("delete | event | modal");
            if (event.value) {
              eventsStore.remove(event.value);
            }
            router.back();
            eventsStore.save();
            if (event.value?.id) {
              diamondDependenciesStore.removeEventTicketDependencies(
                event.value.id
              );
            }
            break;
        }
        modal.dismiss();
      },
      onCancel: () => modal.dismiss(),
    },
  });
  mixpanelTracker.track("open | event setting | event");
  await modal.present();
  await modal.onDidDismiss();
};

const tunnel = (action: string) => {
  tracker.tunnel(
    "event",
    "event page",
    RecordCategory.Events,
    ActionType.Process,
    action
  );
};

const filesChanged = ref(false);

async function addFile(file: any) {
  if (!event.value) {
    return;
  }
  filesChanged.value = true;
  const id = ++nbFiles;
  if (file.media_type === "url") {
    event.value.files.push({
      id,
      media_type: MediaType.Url,
      _type: MediaType.Url,
      key: `link-${id}`,
      url: file.url,
    });
    mixpanelTracker.track("add link | event | events");
  } else {
    const url = file?._file?.url;
    if (!url) return;
    event.value.files.push({
      id,
      media_type: MediaType.File,
      _type: MediaType.File,
      tmpUrl: url,
      tmpName: file.name,
      url: "",
    });
    mixpanelTracker.track("upload file | event | events");
  }
}

function deleteFile(file: any) {
  filesChanged.value = true;
  event.value.files = event.value.files.filter((f) => f.id !== file.id);
}

const { addFileItem } = useAddFileItem(
  tunnel,
  addFile,
  "add file | event | event"
);
</script>

<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar class="ion-padding-start transparent-toolbar">
        <back-button
          slot="start"
          :text="$t('common.saveAndExit')"
          @back="closeEvent"
        />
        <ion-buttons slot="end">
          <ion-button
            v-if="event"
            class="toolbar-end-button"
            @click="openEventOptions"
          >
            <font-icon name="more_horiz" size="1.25" material color="primary" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="eventType" :fullscreen="true" class="event-page">
      <div class="event-content ion-padding-start ion-padding-end">
        <div class="event-type-label">
          {{ eventType?.config?.emoji }}
          {{ localize(eventType.label, locale, 0) }}
        </div>

        <div class="event-metadata">
          <div class="event-created-by-at">
            {{ createdSince }} ∙ {{ creator }}
          </div>
          <EventMissingFields
            v-if="!existingEvent?.isComplete"
            class="event-type-missing"
            :nbFilled="nbRequiredFields - nbMissingFields"
            :nbTotal="nbRequiredFields"
            :requiredFields="requiredEventTypeProperties"
            :missingFields="missingFields"
            :eventType="eventType"
          />
        </div>

        <EventDependencies
          v-if="existingEvent && !creatingEvent"
          :fEvent="existingEvent"
          :disabled="!canEdit"
        />

        <div
          v-for="eventTypeProperty of shownEventTypeProperties"
          :key="eventTypeProperty.id"
          class="event-type-property"
        >
          <EventPropertyField
            :eventTypeProperty="eventTypeProperty"
            :event="event"
            :eventType="eventType"
            @updateEvent="updateEvent"
            :canEdit="canEdit"
          />
        </div>

        <div class="hidden-properties">
          <div
            v-for="eventTypeProperty of hiddenEventTypeProperties"
            :key="eventTypeProperty.id"
            class="event-type-property"
          >
            <div
              class="add-property"
              @click="showProperty(eventTypeProperty.id)"
            >
              <font-icon
                name="add"
                size="0.875"
                color="var(--ion-color-secondary)"
                material
              />
              {{ localize(eventTypeProperty.label, locale, 0) }}
            </div>
          </div>
        </div>

        <!-- EVENT FILES -->
        <div>
          <file-list
            class="file-list-events"
            :viewer="true"
            :files="files"
            :readonly="!canEdit"
            v-if="files && files.length"
            @delete="deleteFile"
          />
          <div
            class="events-add-file"
            v-if="canEdit"
            :class="{
              'no-file': !files?.length,
            }"
            @click="addFileItem()"
          >
            <font-icon
              name="add"
              size="0.875"
              material
              color="var(--ion-color-secondary)"
            />
            {{ t("tickets.newFile") }}
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
.event-page {
  --background: transparent;
}

.event-page .event-content {
  background: var(--ion-color-primary-contrast);
  border-radius: var(--ion-padding);
  padding-top: calc(var(--ion-padding) * 1);
  min-height: 100%;
}

.event-type-label {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--ion-color-primary);
  margin-top: var(--ion-padding);
  font-size: var(--font-size-xl);
}

.event-missing-fields {
  font-size: var(--font-size-md);
  color: var(--ion-color-danger);
  margin-top: var(--ion-padding);
}

.event-type-property {
  font-size: var(--font-size-md);
  color: var(--ion-color-primary);
  margin-top: var(--ion-padding);
  margin-bottom: var(--ion-padding);
}

.event-type-missing {
  font-size: var(--font-size-md);
  color: var(--ion-color-primary);
  margin-top: var(--ion-padding);
  width: fit-content;
}

.add-property {
  background-color: var(--ion-background-color-shade);
  border: 1px solid var(--ion-border-color);
  color: var(--ion-color-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  border-radius: 50px;
  padding: calc(var(--ion-padding) / 2) var(--ion-padding);
  font-size: var(--font-size-s);
  margin: 0;
  max-width: 200px;
  width: fit-content;
}

.hidden-properties {
  padding-top: calc(var(--ion-padding) / 2);
}

.event-metadata {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--ion-padding);
  margin-bottom: calc(var(--ion-padding) * 2);
}

.event-created-by-at {
  flex: 1;
  padding-top: calc(2 * var(--ion-padding));
  font-size: var(--font-size-s);
  color: var(--ion-color-primary-shade);
}

.events-add-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  gap: var(--ion-padding);
  color: var(--ion-color-secondary);
  font-size: var(--font-size-s);
  padding: calc(var(--ion-padding) / 2);
  margin: var(--ion-padding);
  border-radius: var(--f-border-radius);
}

.file-list-events {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
}
</style>
