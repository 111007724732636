<script lang="ts" setup>
import { computed } from "vue";
import { IonContent, IonItem, IonLabel, IonList } from "@ionic/vue";
import { useI18n } from "@/composables/useI18n";
import { useEventTypesStore } from "@/store/eventTypes";
import { EventType } from "@/types";
import { storeToRefs } from "pinia";
import mixpanelTracker from "@/utils/mixpanel-tracker";
import { useConfigStore } from "@/store/config";
import { useFabriqStore } from "@/store/fabriq";
import { localize } from "@/utils/localize";

const emit = defineEmits(["done", "cancel"]);
const { t } = useI18n();
const eventTypesStore = useEventTypesStore();
const configStore = useConfigStore();
const { config } = storeToRefs(configStore);
const { locale } = useFabriqStore();

const done = (ev: string) => {
  emit("done", ev);
};

const eventTypes = computed(() => {
  const eventTypes: any = [];
  const favoriteEventTypes = [...(config.value?.favoriteEventTypes || [])];
  eventTypesStore.collection.forEach((t: EventType) => {
    if (t.deletedAt) return;
    const favorite = favoriteEventTypes.includes(t.id);
    let label = localize(t.label, locale, 0);
    if (!label && typeof t.label === "object") {
      const key = Object.keys(t.label)[0];
      label = localize(key, locale, 0);
    }
    eventTypes.push({
      ...t,
      label,
      emoji: t.config?.emoji || "🏭",
      favorite,
    });
  });
  eventTypes.sort((a: any, b: any) => {
    if (a.favorite && !b.favorite) return -1;
    if (!a.favorite && b.favorite) return 1;
    if (!b.id) return 1;
    return a.label.localeCompare(b.label);
  });
  return eventTypes;
});

const makeEventTypeFavorite = (t: any) => {
  const favoriteId = t.id;
  mixpanelTracker.track(
    "mark as favorite | event eventType | event eventTypes modal"
  );
  if (t.favorite) {
    configStore.removeFavoriteEventType(favoriteId);
  } else {
    configStore.addFavoriteEventType(favoriteId);
  }
  configStore.saveUserConfig();
};
</script>

<template>
  <ion-content class="ion-padding-top normal">
    <ion-list lines="none" class="ion-margin-top">
      <ion-item>
        <div class="hint">{{ t("events.eventTypeModal.title") }}</div>
      </ion-item>
    </ion-list>
    <ion-list lines="none" class="ticket-event-types-list">
      <ion-item
        v-for="eventType of eventTypes"
        :key="eventType.id"
        @click="done(eventType.id)"
        class="clickable"
      >
        <font-icon
          v-if="!eventType.id"
          slot="start"
          name="insert_drive_file"
          material
          outlined
          color="var(--ion-color-primary)"
        />
        <div v-else slot="start" class="emoji">
          {{ eventType.emoji }}
        </div>
        <ion-label class="list-item">{{ eventType.label }}</ion-label>
        <font-icon
          v-if="eventType.id"
          slot="end"
          class="clickable make-favorite"
          @click.stop.prevent="makeEventTypeFavorite(eventType)"
          material
          size="0.813"
          :outlined="!eventType.favorite"
          :name="eventType.favorite ? 'star' : 'grade'"
          :color="
            eventType.favorite
              ? 'var(--ion-color-warning)'
              : 'var(--f-color-discrete)'
          "
        >
        </font-icon>
      </ion-item>
      <br />
    </ion-list>
  </ion-content>
</template>

<style scoped>
.hint {
  font-size: var(--font-size-m);
}

.list-item {
  font-size: var(--font-size-m);
}

.make-favorite {
  padding: 10px;
  margin-right: -6px;
}

.emoji {
  font-size: 0.8rem;
}

.ticket-event-types-list {
  height: calc(100vh - 210px);
  overflow-y: auto;
}
</style>
