import { type Ref, computed, ref, watch } from "vue";
import { EventsService } from "@/services/events.js";
import { FabriqFile, MediaType } from "@/types/ticket.js";
import { FabriqEvent, FabriqEventPayload } from "@/types/events.js";

export function useEventFiles(event: Ref<FabriqEventPayload | FabriqEvent>) {
  const eventFiles: Ref<FabriqFile[]> = computed(() => [...event.value.files]);
  const files: Ref<FabriqFile[]> = ref([]);

  const filesWithLoadingFlag = computed(() => {
    return eventFiles.value.map((file) => {
      if (file.tmpUrl) {
        return {
          ...file,
          loading: false,
        };
      }
      const loaded = files.value.find((f) => f.key === file.key);
      return {
        ...(loaded ?? file),
        loading: !loaded?._file,
      };
    });
  });

  const loadedFiles: Map<string, FabriqFile> = new Map();

  async function loadMissingFiles(filesList: FabriqFile[]) {
    const loadingFiles: FabriqFile[] = [];
    for (const file of filesList) {
      if (file.media_type === MediaType.Url) {
        loadingFiles.push(file);
        continue;
      }
      if (file.tmpUrl) {
        loadingFiles.push(file);
        continue;
      }
      if (!loadedFiles.has(file.key!)) {
        const fileResponse = await EventsService.fetchEventFile(file.key!);
        if (fileResponse.outcome === "fetched") {
          const loaded = {
            ...file,
            _file: {
              url: fileResponse.url,
            },
          };
          loadingFiles.push(loaded);
          loadedFiles.set(file.key!, loaded);
        }
      } else {
        loadingFiles.push(loadedFiles.get(file.key!)!);
      }
    }
    files.value = loadingFiles;
  }

  let timer: any; // This timer is used to prevent multiple file loads when multiple files are added at once
  watch(
    eventFiles,
    async (filesList) => {
      if (timer) {
        clearTimeout(timer);
      }
      setTimeout(() => loadMissingFiles(filesList), 500);
    },
    { immediate: true, deep: true }
  );

  return { files: filesWithLoadingFlag };
}
