import { useFabriqStore } from "@/store/fabriq";
import { Browser } from "@capacitor/browser";
import { useI18n } from "./useI18n";
import { useFilesStore } from "@/store/files";
import { FabriqFile } from "@/types";
import { format, parseISO } from "date-fns";
import { computed, ComputedRef, ref } from "vue";
import { transformUrl } from "@/utils/files";

export const useFile = (file: ComputedRef<FabriqFile>) => {
  const { t } = useI18n();
  const filesStore = useFilesStore();
  const fabriqStore = useFabriqStore();
  const image = ref();
  const src = computed(() => {
    if (file.value.media_type !== "file") return;
    if (file.value.tmpUrl) {
      return file.value.tmpUrl;
    }
    return file.value.key
      ? `${fabriqStore.subdomain}${file.value._file?.url}`
      : file.value._file?.url;
  });

  const openFile = (ev?: any) => {
    if (!file.value.id) return sendFile();
    if (isImage.value) {
      if (ev?.target === image.value.$el) return;
      const img: HTMLElement | undefined = image.value.$el.querySelector("img");
      if (!img) return;
      img.click();
      return;
    }

    if (file.value.tmpUrl) {
      Browser.open({ url: file.value.tmpUrl });
      return;
    }
    const url = file.value.key
      ? file.value.url
      : transformUrl(
          file.value.media_type === "file" ? src.value : file.value.url,
          fabriqStore.token,
          fabriqStore.subdomain
        );
    if (!url) return;
    Browser.open({ url });
  };

  const sendFile = async () => {
    const file = filesStore.collection.find(
      (f: FabriqFile) => f.uuid === file.value.uuid
    );
    if (!file) return;
    await filesStore.save();
  };

  const filename = computed(() => {
    if (file.value.filename) return file.value.filename;
    if (file.value.media_type === "file") {
      if (file.value.tmpName) return file.value.tmpName;
      if (file.value.key) return file.value.key;
      return file.value._file?.url?.replace(/^.*\/([^/]+)\?.*/, "$1");
    } else {
      return file.value.url?.replace(/^https?:\/\/([^/]+).*$/, "$1");
    }
  });

  const name = computed(() => {
    if (!file.value.id) return t("common.uploading");
    return filename.value;
  });

  const extension = computed(() => {
    if (file.value.media_type !== "file") {
      const url = String(file.value.url || "");
      if (url.includes("onedrive.live.com") && url.includes("xlsx"))
        return "xlsx";
      else if (url.includes("onedrive.live.com") && url.includes("docx"))
        return "xlsx";
      else if (url.includes("onedrive.live.com") && url.includes("pptx"))
        return "pptx";
      else if (url.includes("gitlab.com")) return "gitlab";
      else if (url.includes("notion.so")) return "notion";
      else if (url.includes("slack.com")) return "slack";
      else if (url.includes("airtable.com")) return "airtable";
      else if (url.includes("mixpanel.com")) return "mixpanel";
      else if (url.includes("pipedrive.com")) return "pipedrive";
      else if (url.includes("postman.com")) return "postman";
      else if (url.includes("loom.com")) return "loom";
      else if (url.includes("youtube.com")) return "youtube";
      else if (url.includes("youtu.be")) return "youtube";
      else return "link";
    }
    if (!filename.value?.length) return "";
    const extension = filename.value
      .replace(/^.*\.([^.]*)$/, "$1")
      .toLocaleLowerCase();
    return extension;
  });

  const isImage = computed(() => {
    if (!extension.value?.length) return false;
    return ["jpg", "jpeg", "png", "git", "bmp", "ico", "svg"].includes(
      extension.value
    );
  });

  const dateFormat = t("formats.dateVerboseShy");
  const createdAt = computed(() => {
    if (!file.value.created_at) return null;
    const createdAt = parseISO(file.value.created_at);
    return format(createdAt, dateFormat);
  });

  return {
    createdAt,
    isImage,
    name,
    image,
    src,
    extension,
    sendFile,
    openFile,
  };
};
