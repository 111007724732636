<script lang="ts" setup>
import { ComputedRef, computed, onBeforeUnmount, onMounted, ref } from "vue";
import { IonItemGroup, IonItem } from "@ionic/vue";
import {
  EventPropertyWithReadableValue,
  FabriqEventWithReadableProperties,
} from "@/types";
import { useGetEventPropertiesByType } from "@/composables/useGetEventPropertiesByType";
import FontIcon from "@/components/ui/FontIcon.vue";
import { format } from "date-fns";
import { useFabriqStore } from "@/store/fabriq";
import { useClientStore } from "@/store/client";
import { storeToRefs } from "pinia";
import { useEventFiltersStore } from "@/store/eventFilters";
import { localize } from "@/utils/localize";
import { useDiamondDependenciesStore } from "@/store/diamondDependencies";
import { useAddTicketFromEvent } from "@/composables/useAddTicketFromEvent";
import { getShortDescriptionFromEventProperties } from "@/utils/events";

type EventsListItemProps = {
  fEvent: FabriqEventWithReadableProperties;
};

const props = defineProps<EventsListItemProps>();

const {
  getEventPropertiesByType,
  getPropertyByEventPropertyTypeId,
  eventType,
} = useGetEventPropertiesByType(computed(() => props.fEvent));

const fabriqStore = useFabriqStore();
const clientStore = useClientStore();
const eventFilterStore = useEventFiltersStore();
const dependenciesStore = useDiamondDependenciesStore();

const { eventTypeId } = storeToRefs(eventFilterStore);

const { usersWithFutureUserId: users } = storeToRefs(clientStore);

const { online } = storeToRefs(fabriqStore);

const eventTypeLabel = computed(() => {
  return localize(eventType.value?.label, fabriqStore.locale, 0);
});

const user = computed(() => {
  const fUser = users.value?.find(
    (u) => u.future_user_id === props.fEvent.createdBy
  );
  if (!fUser) return "";
  return (fUser.first_name ?? "").substring(0, 1) + ". " + fUser.last_name;
});

const emoji = computed(() => {
  return eventType.value?.config?.emoji;
});

const eventZoneValue = computed(() => {
  const zone = getEventPropertiesByType(props.fEvent, "zone");
  if (!zone) {
    return "no zone";
  }
  return zone.readableValue ? zone.readableValue : `[${zone.value}]`;
});

const eventDateValue: ComputedRef<string> = computed(() => {
  const dateValue = (
    (getEventPropertiesByType(props.fEvent, "datetime")?.value as string) ?? ""
  ).replace("Z", "");
  if (!dateValue) return "";
  return format(new Date(dateValue), "HH:mm");
});
const mainPropertiesReadableValues = computed(() => {
  const propertyNames = props.fEvent.properties.reduce<string[]>(
    (acc: string[], property: EventPropertyWithReadableValue) => {
      const propertyType = getPropertyByEventPropertyTypeId(
        property.eventPropertyTypeId
      );

      if (!propertyType) return acc;

      if (!property?.readableValue) return acc;

      // FIXME for now, we use ordinal/cardinal values as main properties
      // eventually, we need to taint a property as main property
      if (["ordinal", "cardinal"].includes(propertyType.type)) {
        return [...acc, property.readableValue];
      }

      return acc;
    },
    []
  );

  return propertyNames;
});

const eventElement = ref(null);

const { openAddMenu, eventDependencies, dependenciesLoading, canEdit } =
  useAddTicketFromEvent(props.fEvent, true);

const observer: IntersectionObserver = new IntersectionObserver(
  (entries) => {
    if (entries.some((entry) => entry.isIntersecting)) {
      loadDependencies();
    }
  },
  { threshold: 0.8 }
);

onMounted(() => {
  if (eventElement.value) observer.observe(eventElement.value);
});

onBeforeUnmount(() => {
  observer?.disconnect();
});

async function loadDependencies() {
  dependenciesLoading.value = true;

  if (eventDependencies.value.length) {
    dependenciesLoading.value = false;
    return;
  }
  await dependenciesStore.all({
    entityType: "event",
    entityId: props.fEvent.id,
  });

  observer?.disconnect();
  dependenciesLoading.value = false;
}

const eventShortDescriptionValue = computed(() => {
  const { properties } = props.fEvent;
  const shortDescriptionProperty =
    getShortDescriptionFromEventProperties(properties);
  if (!shortDescriptionProperty) return;
  return shortDescriptionProperty?.readableValue;
});
</script>

<template>
  <ion-item-group class="f-list-item f-list-item-ticket clickable">
    <ion-item
      lines="none"
      class="event-divider"
      @click="$emit('select', fEvent.uuid)"
    >
      <div class="event-container" ref="eventElement">
        <div class="event-body">
          <div class="event-row" v-if="eventTypeId == null">
            <div class="event-type" v-if="eventTypeLabel">
              <div v-if="emoji" class="emoji-item">
                {{ emoji }}
              </div>
              <div>
                {{ eventTypeLabel }}
              </div>
            </div>

            <div class="add-ticket-button">
              <font-icon
                v-if="dependenciesLoading"
                name="spinner"
                spin
                size="0.3"
                class="dependencies-loading"
              />
              <font-icon
                v-else
                size="1.5"
                name="post_add"
                material
                @click.stop.prevent="openAddMenu"
                :color="
                  !online ? 'var(--f-color-disabled)' : 'var(--f-color-primary)'
                "
              />
              <div class="badge" v-if="eventDependencies.length" />
            </div>
          </div>
          <div class="event-row">
            <template v-if="mainPropertiesReadableValues.length > 0">
              <div
                class="event-property"
                v-for="mainPropertiesReadableValue of mainPropertiesReadableValues"
                :key="mainPropertiesReadableValue"
              >
                <div class="event-property-text">
                  {{ mainPropertiesReadableValue }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="spacer" />
            </template>

            <div class="add-ticket-button" v-if="eventTypeId !== null">
              <font-icon
                v-if="dependenciesLoading"
                name="spinner"
                spin
                size="0.3"
                class="dependencies-loading"
              />
              <font-icon
                v-else
                size="1.5"
                name="post_add"
                material
                @click.stop.prevent="openAddMenu"
                :color="
                  eventDependencies.length || !online || !canEdit
                    ? 'var(--f-color-disabled)'
                    : 'var(--f-color-primary)'
                "
              />
              <div class="badge" v-if="eventDependencies.length" />
            </div>
          </div>
        </div>
        <div class="event-footer">
          <div
            class="event-short-description"
            v-if="eventShortDescriptionValue"
          >
            {{ eventShortDescriptionValue }}
          </div>

          <div class="event-row">
            <div class="event-date">
              <font-icon
                name="today"
                color="var(--f-color-discrete)"
                material
              />
              <div>
                {{ eventDateValue }}
              </div>
            </div>

            <div class="event-zone">
              <font-icon
                size="0.7"
                color="var(--f-color-discrete)"
                name="location-dot"
              />
              <div class="event-zone-text">
                {{ eventZoneValue }}
              </div>
            </div>

            <div class="event-user">
              <font-icon
                name="person"
                color="var(--f-color-discrete)"
                material
                outlined
              />
              <div class="event-user-text">
                {{ user }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-item>
  </ion-item-group>
</template>

<style scoped>
.event-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.event-body {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.event-footer {
  margin-top: 10px;
}

.event-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.event-property {
  display: flex;
  font-size: var(--font-size-m);
  flex: 1;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  min-width: 0;
}

.event-property-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-ticket {
  flex: 0 1 auto;
}

.event-type {
  display: flex;
  font-size: var(--font-size-s);
  flex: 1 1 auto;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
}

.event-zone,
.event-date {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  min-width: 0;
  font-size: var(--font-size-s);
  color: var(--f-color-discrete);
}
.event-zone {
  flex: 1;
}
.event-user {
  display: flex;
  flex-direction: row;
  min-width: 0;
  gap: 8px;
  align-items: center;
  font-size: var(--font-size-s);
  color: var(--f-color-discrete);
}
.event-zone-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-user-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-short-description {
  font-size: var(--font-size-s);
  color: var(--f-color-discrete);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;
  margin-bottom: 4px;
}

.event-divider::part(native) {
  --background: transparent;
  padding: calc(var(--ion-padding) / 2) 0 calc(var(--ion-padding) / 2)
    var(--ion-padding);
  display: flex;
  align-items: center;
  border: 0;
}

.add-ticket-button {
  position: relative;
}

.badge {
  --badge-size: 0.6rem;
  width: var(--badge-size);
  height: var(--badge-size);
  background-color: var(--ion-color-secondary);
  border-radius: calc(var(--badge-size));
  position: absolute;
  top: 1px;
  right: 1px;
}

.dependencies-loading {
  margin-block: 4.8px;
  margin-inline: 6px;
}
</style>
