<script setup lang="ts">
import { nextTick, ref, watch } from "vue";

const props = withDefaults(
  defineProps<{
    snap: boolean;
    scrollToStep: string;
  }>(),
  {
    snap: false,
  }
);
const emit = defineEmits(["scroll"]);
const scrollDisabled = ref(false);
watch(
  () => props.scrollToStep,
  async (value) => {
    if (value) {
      scrollDisabled.value = true;
      await nextTick();

      const el = document.querySelector(value);
      if (el) {
        el.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      scrollDisabled.value = false;
    }
  }
);

const handleScroll = (ev: any) => {
  if (scrollDisabled.value) return;
  emit("scroll", ev);
};
</script>

<template>
  <div
    class="vertical-scroller hide-scrollbar"
    :class="{ snap }"
    @scroll="handleScroll"
  >
    <slot></slot>
  </div>
</template>

<style>
.vertical-scroller {
  --spacer: 1rem;
  display: flex;
  overflow: auto;
  flex: none;
  flex-flow: column nowrap;

  max-height: 100%;

  overflow-y: auto;
  overscroll-behavior-inline: contain;
}
.vertical-scroller.snap {
  scroll-snap-type: y proximity;
  scroll-padding: calc(var(--spacer) * 0.2);
}

.vertical-scroller.snap > * {
  scroll-snap-align: start;
}
</style>
