import { defineStore, storeToRefs } from "pinia";
import { Category, Client, Field, Group, Label, Status, User } from "@/types";
import { UserService } from "@/services/user";
import storage from "@/utils/storage";
import tracker from "@/utils/tracker";
import { betaClientId } from "@/utils/indicators";
import { useFabriqStore } from "@/store/fabriq";
import { useTeamsStore } from "./teams";

interface ClientState {
  client: any;
  categories: Category[];
  custom_fields: any;
  extra_fields: Field[];
  groups: Group[];
  labels: Label[];
  statuses: Status[];
  users: User[];
  locale: string;
  fields: Record<string, Field[]>;
  config: any;
  diamondUserMapping: {
    future_user_id: string;
    user_id: number;
  }[];
}

export const useClientStore = defineStore("client", {
  state: (): ClientState => {
    return {
      client: null,
      categories: [],
      custom_fields: {},
      extra_fields: [],
      groups: [],
      labels: [],
      statuses: [],
      users: [],
      fields: {},
      locale: "",
      config: {},
      diamondUserMapping: [],
    };
  },
  actions: {
    setClient(client: any) {
      const fabriqStore = useFabriqStore();
      this.locale = fabriqStore.locale;
      this.categories = client.categories;
      this.custom_fields = client.custom_fields;
      this.extra_fields = client.extra_fields || [];
      this.groups = client.groups || [];
      this.labels = client.labels || [];
      this.statuses = client.statuses || [];
      this.users = client.users || [];
      this.config = client.config || {};
      this.client = {
        ...client,
        categories: undefined,
        custom_fields: undefined,
        extra_fields: undefined,
        groups: undefined,
        labels: undefined,
        statuses: undefined,
        users: undefined,
        config: undefined,
      };
      this.setTranslations();
      tracker.setClientId(client.id);
      storage.writeToStorage("client", client);
    },
    async load(): Promise<Client | undefined> {
      try {
        const client = await UserService.getClient();
        const diamondUserMapping = await UserService.getDiamondUserMapping();
        this.diamondUserMapping = diamondUserMapping;
        this.setClient(client);
        return client;
      } catch (e) {
        console.error(e);
      }
    },
    setTranslations(locale?: string) {
      return new Promise(() => {
        this.categories = <Category[]>this.translate(this.categories, locale);
        this.statuses = <Status[]>this.translate(this.statuses, locale);
        this.labels = <Label[]>this.translate(this.labels, locale);
      });
    },
    translate(items: (Category | Status | Label)[], locale?: string) {
      const currentLocale = locale || this.locale;
      return items.map((item) => {
        const originalName = item.originalName || item.name;
        return {
          ...item,
          originalName,
          name: item.config?.i18n?.hasOwnProperty(currentLocale)
            ? item.config?.i18n[currentLocale]
            : originalName,
        };
      });
    },
    async logout() {
      this.client = null;
    },

    findUserByFutureUserId(future_user_id: string): User | undefined {
      return this.usersWithFutureUserId.find(
        (u) => u.future_user_id === future_user_id
      );
    },
  },
  getters: {
    hasKpi(): boolean {
      if (!this.client?.id) return false;
      if (betaClientId.includes(this.client?.id)) return true;
      return !!this.config?.hasMobileKpi;
    },
    useKPIv3(): boolean {
      return this.config?.useKPIv3 || false;
    },
    hasDatapointTicketDependencies(): boolean {
      return this.config?.hasDatapointTicketDependencies || false;
    },
    mobileScreenRotationEnabled(): boolean {
      return this.config?.mobileScreenRotationEnabled || false;
    },
    usersWithFutureUserId(): (User & {
      future_user_id: string;
      fullName: string;
    })[] {
      const teamsStore = useTeamsStore();
      const { collection: teams } = storeToRefs(teamsStore);
      const users: Array<User & { fullName: string; future_user_id: string }> =
        [];
      const teamsUsers = (teams.value || []).reduce((acc: any, a: any) => {
        acc.push(...a.users);
        return acc;
      }, []);
      this.users.forEach((u: User) => {
        if (teams.value?.length && !teamsUsers.includes(u.id)) return;
        const fullName = String(`${u.first_name} ${u.last_name}`);
        const future_user_id =
          this.diamondUserMapping.find((fu) => fu.user_id === u.id)
            ?.future_user_id ?? "";
        if (!future_user_id.length) return;
        users.push({ ...u, future_user_id, fullName });
      });
      users.sort((a, b) => a.fullName.localeCompare(b.fullName));
      return users;
    },
    tmpNewTeamRoles(): boolean {
      return this.config?.tmpNewTeamRoles || false;
    },
  },
});
