import { IndicatorFrequency } from "@/types/indicator";
import {
  DateTimePrecision,
  dateTimeToDateString,
  generateDateTimeFromDate,
} from "./dates";

export function buildDatapointId(
  indicatorId: number,
  dateStr: string,
  frequency: IndicatorFrequency
) {
  let precision: DateTimePrecision = "day";
  switch (frequency) {
    case "weekly":
      precision = "week";
      break;
    case "monthly":
      precision = "month";
      break;
    case "yearly":
      precision = "year";
      break;
    default:
      break;
  }
  const dateTimeAsString = dateTimeToDateString(
    generateDateTimeFromDate(new Date(dateStr), precision)
  );
  return `${indicatorId}-${dateTimeAsString}`;
}
